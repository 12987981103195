.promo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 3.85vw;
  /* padding-bottom: 3.96vh; */
  padding-bottom: 6.593vh;
  height: 78.9vh;
  background: url(../../resources/img/PortfolioPage.webp) center center/cover
    no-repeat;
  z-index: -1;
  margin-bottom: 6.81vh;
}

.promoDescr {
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  color: rgba(255, 255, 255, 1);
  z-index: 1;
}

.promoDescr span {
  font-weight: 600;
}

.promoRectangle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

.slidesWrap {
  height: 430px;
  margin: 6.264vh 0 6.264vh 0;
}

.executed {
  padding: 0 2.55vw 0 3.18vw;
  font-size: 2.64vh;
  font-weight: 400;
  line-height: 3.3vh;
  /* color: rgba(255, 255, 255, 1); */
}

/* .executedItem {
  width: 100%;
  height: 54.62vh;
  background-size: 100% 100%;
  padding: 8.90vh 3.44vw 8.90vh 3.65vw;
  display: grid;
  grid-template-columns: 21.72vw 1fr;
  column-gap: 5.10vw;
  margin-bottom: 62px;
}

.executedItemIMG {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.executedItemName {
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;
}

.executedItemBot {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.executedItemBot span {
  font-weight: 600;
} */

.possibilities {
  /* padding: 0 6.35vw; */
  /* padding: 0 2.60vw; */
  margin-bottom: 51px;
  font-size: 2.64vh;
  font-weight: 400;
  line-height: 30px;
}

.possibilitiesTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: rgba(75, 125, 62, 1);
  margin-top: 36px;
  padding: 0 4.37vw 0 5.48vw;
}

.possibilities p {
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  padding: 0 4.37vw 0 5.48vw;
  margin-top: 2.747vh;
}

.possibilitiesDescr {
  margin-top: 2.198vh;
  padding: 0 4.37vw 0 5.48vw;
}

.possibilitiesDescr ul {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  padding-left: 1.94vw;
}

.possibilitiesDescr span {
  display: block;
  font-style: italic;
  margin-top: 2.198vh;
}