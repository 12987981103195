@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&family=Montserrat:wght@400;600&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}



.swiper-button-prev {
  color: transparent !important;
  background-image: url(./resources/swiper/arrowPrev.svg) !important;
  width: 33px !important;
  height: 50px !important;
}

.swiper-button-next {
  color: transparent !important;
  background-image: url(./resources/swiper/arrowNext.svg) !important;
  width: 33px !important;
  height: 50px !important;
}

article .swiper-button-next {
  right: 30px !important;
}

article .swiper-button-prev {
  left: 30px !important;
}

.swiper-slide {
  visibility: hidden !important;
}

.swiper-slide-active {
  visibility: visible !important;
}

.swiper-slide-next {
  visibility: visible !important;
}

