
.swiper {
  height: 100%;
  /* overflow: clip; */
  overflow: hidden;
}

.sliderPortfolio {
  height: 100% !important;
  /* padding: 0 6.39vw; */
  /* padding: 0 70px; */
  padding: 0 6.35vw;
}

.swiper-slide {
  height: 100%;
}

.slideImg {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.swiper-button-prev {
  display: block;
  top: 50%;
  left: 20px;
  color: rgba(75, 125, 62, 1);
}

.swiperButtonNext {
  display: block;
  top: 50%;
  right: 120px !important;
  color: rgba(75, 125, 62, 1);
}

.slide{
  /* padding: 0 30px; */
  /* width: 47.40vw; */
  /* overflow: hidden !important; */
  /* margin-right: 1.56vw; */
}

.slidesWrap .swiper-button-next {
  left: 50px !important;
}