.container {
  box-shadow: rgb(28, 27, 27);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  margin: 0 auto;
  margin-top: 20px;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.text {
  color: rgb(0, 0, 0);
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 65px;
  height: 65px;
}

.loader {
  border: 5px solid rgb(188, 188, 188);
  border-top: 5px solid rgba(75, 125, 62, 1);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
