.promo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 74px;
  padding-bottom: 36px;
  height: 78.9vh;
  background: url(../../resources/img/ContactsPage.webp) center center/cover
    no-repeat;
  z-index: -1;
  margin-bottom: 3.85vh;
}

.promoDescr {
  font-size: 5.27vh;
  font-weight: 400;
  line-height: 6.37vh;
  color: rgba(255, 255, 255, 1);
  z-index: 1;
}

.promoDescr span {
  font-weight: 600;
}

.promoRectangle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

.promoContacts {
  padding: 0 6.09vw 0 13.23vw;
}

.promoTitle {
  /* font-size: 48px; */
  font-size: 5.27vh;
  font-weight: 600;
  /* line-height: 58px; */
  line-height: 6.37vh;
  color: rgba(75, 125, 62, 1);
  /* margin-bottom: 33px */
  margin-bottom: 3.63vh;
}

.promoWrapper {
  display: grid;
  grid-template-columns: 34.22vw 37.86vw;
  column-gap: 8.44vw;
  margin-bottom: 2.86vh;
}

.promoPositions {
  /* font-size: 24px; */
  font-size: 2.64vh;
  font-weight: 400;
  /* line-height: 30px; */
  line-height: 3.3vh;
}

.promoPositions span {
  font-weight: 700;
}

.promoEmail {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
}

.promoEmail a {
  text-decoration: none;
  color: rgba(75, 125, 62, 1);
}

.wrapperPhone {
  color: rgba(0, 0, 0, 1);
  text-decoration: none;
}
