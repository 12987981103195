.promo {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 3.85vw;
    /* padding-bottom: 3.96vh; */
    padding-bottom: 6.593vh;
    height: 78.9vh;
    background: url(../../resources/img/ImplementationPage.webp) center center/cover
      no-repeat;
    z-index: -1;
    margin-bottom: 8.242vh;
  }
  
  .promoDescr {
    font-size: 48px;
    font-weight: 400;
    line-height: 60px;
    color: rgba(255, 255, 255, 1);
    z-index: 1;
  }
  
  .promoDescr span {
    font-weight: 600;
  }
  
  .promoRectangle {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 0;
  }

  .promoPossibilities {
    padding: 0 9.69vw;
    margin-bottom: 8.242vh;
  }

  .possibilitiesWrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 4.11vw;
    grid-template-rows: 48.791vh;
    grid-auto-rows: 48.791vh;
    row-gap: 8.242vh;
  }

  .possibilitiesItem {
    width: 100%;
    height: 100%;
    border-radius: 53px;
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.25);
    padding: 3.846vh 0.42vw 3.846vh 0.55vw;
  }

  .possibilitiesTitle {
    font-size: 2.637vh;
    text-align: center;
    font-weight: 600;
  }

  .possibilitiesImg {
    height: 21.429vh;
    width: 8.28vw;
    margin: 0 auto;
    margin-top: 1.758vh;
  }

  .possibilitiesImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .possibilitiesDescr {
    margin-top: 2.637vh;
    font-size: 1.703vh;
    font-weight: 400;
    text-align: start;
  }

  .possibilitiesDescr span {
    font-weight: 600;
  }

  .enumerationWrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4.06vw;
    grid-template-rows: 48.791vh;
    margin-top: 8.242vh;
  }

  .enumerationEquipment {
    border-radius: 53px;
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.25);
    padding: 4.615vh 1.46vw 6.044vh 1.46vw;
  }

  .enumerationTool {
    position: relative;
    border-radius: 53px;
    box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.25);
    padding: 4.615vh 2.86vw 2.198vh 3.33vw;
    overflow: hidden;
  }

  .enumerationGradient {
    position: absolute;
    width: 100%;
    height: 44px;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  }

  .enumerationTitle {
    font-size: 2.637vh;
    font-weight: 700;
    line-height: 3.297vh;
    text-align: center;
  }

  .enumerationSubTitle {
    margin-top: 2.198vh;
    display: flex;
    justify-content: space-between;
    font-size: 1.538vh;
    font-weight: 700;
  }

  .enumerationSubTitle span {
    display: block;
    padding-right: 3px;
  }

  .Equipment {
    margin-top: 2.198vh;
    font-size: 1.538vh;
    font-weight: 400;
    line-height: 2.418vh;
  }

  .Tool {
    display: flex;
    justify-content: space-between;
    height: 34.264vh;
    font-size: 1.538vh;
    font-weight: 400;
    line-height: 2.418vh;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: rgba(232, 232, 232, 1) #ffffff ;
  }

  .Tool::-webkit-scrollbar {
    width: 0.63vw;
  }

  .Tool::-webkit-scrollbar-thumb {
    width: 0.63vw;
    background-color: rgba(232, 232, 232, 1);
    border-radius: 40px
  }

  .quantity {
    text-align: center;
  }




  
  