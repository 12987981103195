.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  background-color: rgba(0, 0, 0, 0.8);
  padding-left: 20px;
  padding-bottom: 7px;
  margin-bottom: 100px;
  color: rgba(0, 0, 0, 1);
}

.wrapperLogo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.wrapperLogo a {
  display: flex;
  align-items: center;
}

.wrapperSection {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  color: rgba(255, 255, 255, 1);
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  margin-right: 20px;
}

.subHeader {
  position: absolute;
  top: 88px;
  left: 0;
  height: 108px;
  width: 100%;
  /* background-color: rgba(75, 125, 62, 0.8); */
  overflow: hidden;
  display: flex;
}

.subHeaderRectangle {
  position: absolute;
  background-color: rgba(75, 125, 62, 0.8);
  width: 100%;
  height: 14px;
}

/* .subHeader div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: -1;
} */

/* .subHeader div img {
  margin: 0 0.26vw 0 0.26vw;
} */

/* .wrapperFirstHalf, .wrapperSecondHalf {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 18px;
  right: 0;

  animation: ticker 20s infinite linear forwards;
}

.wrapperSecondHalf {
  animation: 20s ticker 10s infinite linear forwards;
}



@keyframes ticker {
  0% {
    transform: translate(100%, 0);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
} */

.wrapperFirstHalf,
.wrapperSecondHalf {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 18px;
  /* right: 0; */

  /* animation: ticker 20s infinite linear forwards; */
}

.wrapperFirstHalf {
  animation: ticker1 50s infinite linear forwards;
}

.wrapperSecondHalf {
  transform: translate(100%, 0);
  animation: ticker2 50s infinite linear forwards;
  /* animation: 20s ticker 10s infinite linear forwards; */
}

/* @keyframes ticker {
  0% {
    transform: translate(100%, 0);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
} */

@keyframes ticker1 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}
@keyframes ticker2 {
  0% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

.section {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  transition: all 0.4s;
}

.section:hover {
  color: rgba(75, 125, 62, 0.8);
  text-decoration: none;
  transition: all 0.4s;
}

.section:focus {
  text-decoration: none;
}

.activeSection:focus {
  transition: all 0.4s;
}

.activeSection {
  transition: all 0.4s;
}

.activeSection::after {
  position: absolute;
  bottom: -41px;
  content: '';
  width: 100%;
  border-top: 12px solid #fcfcfc;
  border-radius: 2px;
  box-shadow: 0px 0px 3px #158ef8;
  animation: mymove 500ms;
  z-index: 2;
}

.reverseAnimation::after {
  position: absolute;
  bottom: -41px;
  content: '';
  width: 100%;
  border-top: 12px solid #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 3px #158ef8;
  animation: reversemove 500ms;
  z-index: 2;
}

@keyframes mymove {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

@keyframes reversemove {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
