.promoRectangle {
  /* position: absolute; */
  width: 100%;
  height: 100%;
  /* top: 0;
    left: 0; */
  /* background-color: rgba(0, 0, 0, 0.4); */
  z-index: 1;
}

.promoIMG {
  width: 100%;
  height: calc(100vh - 180px);
  /* height: 100%; */
  background: center center/cover no-repeat;
  filter: brightness(60%);
  z-index: 1;
}

.promoDescr {
  position: absolute;
  left: 3.85vw;
  bottom: 6.593vh;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  color: rgba(255, 255, 255, 1);
  z-index: 5;
}

.promoDescrTitle {
  font-weight: 600;
  font-size: 48px;
  line-height: 60px;
}

.promoDescrSubTitle {
  font-weight: 500;
  font-size: 48px;
  line-height: 60px;
}

.swiper-pagination-bullet {
  width: 25px;
  height: 25px;
  background-color: rgba(255, 255, 255, 1);
  border: 2px solid rgba(75, 125, 62, 1);
  opacity: 1;
  box-shadow: 0px 4px 4px 0px #ffffff40;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}

.swiper-pagination-bullet-active {
  background-color: rgba(75, 125, 62, 1);
  border: 2px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px 0px #ffffff40;
  box-shadow: 0px 4px 4px 0px #00000040 inset;
}

.swiper-pagination {
  transition: 3000ms opacity;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 2px;
}
