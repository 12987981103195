.executedItem {
  position: relative;
  width: 100%;
  height: fit-content;

  padding: 8.9vh 3.44vw 8.9vh 3.65vw;
  display: grid;
  grid-template-columns: 21.72vw 1fr;
  column-gap: 5.1vw;
  margin-bottom: 62px;
  z-index: -1;
  color: rgba(255, 255, 255, 1);
}

.executedItemWrap {
  z-index: 1;
}

.executedBlur {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: brightness(60%) blur(8px);
  z-index: 0;
}

.executedItemIMG {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.executedItemName {
  /* font-size: 48px; */
  font-size: 5.275vh;
  font-weight: 600;
  z-index: 1;
  line-height: 60px;
}

.executedItemBot {
  display: grid;
  z-index: 1;
  grid-template-columns: 1fr 1fr;
}

.executedItemBot span {
  z-index: 1;
  font-weight: 600;
}
