.promo {
  position: relative;
  height: 78.9vh;
  max-width: 100vw;
}

.promoDescr {
  position: absolute;
  left: 3.85vw;
  bottom: 6.593vh;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;
  color: rgba(255, 255, 255, 1);
  z-index: 1;
}

.promoDescr span {
  font-weight: 600;
}
