.promo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 3.85vw;
  /* padding-bottom: 3.96vh; */
  padding-bottom: 6.593vh;
  height: 78.9vh;
  background: url(../../resources/img/LaboratoryPage.webp) center center/cover
    no-repeat;
  z-index: -1;
  margin-bottom: 6.15vh;
}

.promoDescr {
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
  color: rgba(255, 255, 255, 1);
  z-index: 1;
}

/* .promoDescr p {
  margin-left: 15px;
} */

.promoDescr span {
  font-size: 48px;
  line-height: 60px;
  font-weight: 600;
}

.promoRectangle {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

.promoPossibilities {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  padding: 0 12.14vw 0 11.38vw;
}

li {
  margin-left: 2.03vw;
}
