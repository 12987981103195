.wrapper {
  padding: 3.08vh 1.3vw 2.75vh 1.3vw;
  display: grid;
  /* grid-template-columns: 563px 301px; */
  /* grid-template-columns: 29.32vw 15.68vw; */
  /* grid-template-columns: 30vw 25vw; */
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  font-weight: 400;
  /* font-size: 24px; */
  font-size: 2.64vh;
  /* line-height: 30px; */
  line-height: 3.3vh;
}

.wrapperLocation {
  display: grid;
  /* grid-template-columns: 50px 496px; */
  /* grid-template-columns: 2.60vw 25.83vw; */
  /* grid-template-columns: 50px 500px; */
  /* grid-template-columns: 2.6vw 29.04vw; */
  grid-template-columns: 2.6vw 29.04vw;
  column-gap: 0.89vw;
}

.wrapperLocation img {
  width: 2.6vw;
  height: 5.49vh;
}

.wrapperContacts a {
  color: rgba(75, 125, 62, 1);
  text-decoration: none;
}

.wrapperContacts .wrapperPhone {
  color: rgba(0, 0, 0, 1);
}

.link {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
}
